if (module.hot) {
  module.hot.accept();
}

import customSelect from 'custom-select';

$(document).ready(function() {
  $(".sidebar-opener").on("click", function(){
    $("#wrapper").toggleClass('wide-content');
    sidebarCookies();
  });
  $(".dropdown-toggle").on("click", function(e){
    e.preventDefault();
    $(this).toggleClass('active');
    $(this).siblings( ".dropdown-menu" ).toggleClass('show');
  });

  $(".page-section__opener").on("click", function(e){
    e.preventDefault();
    $(this).closest(".page-section").toggleClass('collapsed');
  });

  const cstSel = customSelect('select');
  $(".filter-form button[type='reset']").on("click", function(){
    $.each(cstSel, function(i, el) {
      el.value="";
    })
  });

  let isSidebarHidden = Cookies.get('sidebar') == 'hide';
  if (window.innerWidth <= 767) {
    debugger;
    $("#wrapper").addClass('wide-content');
    $("#filter").addClass('collapsed');
    Cookies.set('sidebar', 'hide');
  }
  if (isSidebarHidden) {
    $("#wrapper").addClass('wide-content');
  }

  function sidebarCookies() {
      setTimeout(() => {
        $("#wrapper").hasClass('wide-content')
              ? Cookies.set('sidebar', 'hide')
              : Cookies.set('sidebar', 'show');
      }, 1000)
  }

});
